import "./TrainerCard.css";
const TrainerCard: React.FC = () => {
  return (
    <section className="auto-trainers-section">
      <h2 className="section_title">Meet Our Trainers</h2>
      <div className="wrapper_trainers_section">
        <div className="card">
          <img src="/pictures/9.jpg" alt="Auto Trainer 1" />
          <div className="card-content">
            <div className="trainer-name">John Doe</div>
            <div className="trainer-description">
              Experienced auto trainer with a passion for helping clients
              achieve their fitness goals. Specializes in personalized training
              programs and vehicle-inspired workouts.
            </div>
          </div>
        </div>

        <div className="card">
          <img src="/pictures/10.jpg" alt="Auto Trainer 2" />
          <div className="card-content">
            <div className="trainer-name">Jane Smith</div>
            <div className="trainer-description">
              Certified auto trainer dedicated to promoting health and wellness
              through creative and engaging fitness routines. Enthusiastic about
              inspiring others to lead an active lifestyle.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TrainerCard;
