import React, { useState } from "react";

import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";

import { PiWhatsappLogoLight } from "react-icons/pi";
import TrainerCard from "./TrainerCard";

function App() {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("Toggling menu");
    setMenuVisible(!menuVisible);
  };
  return (
    <div className="App">
      <div className="header">
        <div className="logo">Sporty</div>
        <BrowserRouter>
          <div
            className={`burger ${menuVisible ? "open" : ""}`}
            onClick={toggleMenu}
          >
            {menuVisible ? "✕" : "☰"}
          </div>
          <ul className={`menu ${menuVisible ? "open" : ""}`}>
            <li>
              <Link smooth to="/pathLink#main">
                Home
              </Link>
            </li>
            <li>
              <Link smooth to="/pathLink#classes">
                Classes
              </Link>
            </li>
            <li>
              <Link smooth to="/pathLink#about">
                About
              </Link>
            </li>

            <li>
              <Link smooth to="/pathLink#contact">
                Contact
              </Link>
            </li>
          </ul>{" "}
        </BrowserRouter>
      </div>
      <section className="first_section" id="main">
        <div className="image-container">
          {/* Add your image here */}
          <img src="/pictures/7.png" alt="" />
          <div className="circle-container">
            <div className="circle first-circle"></div>
            <div className="circle second-circle"></div>
            <div className="circle third-circle"></div>
          </div>
          <div className="slogan">CHANGE YOUR LIFE</div>
          <div className="rotatingText">
            <div className="rotatingText-content">
              <p className="rotatingText-description">
                Commit To Be Fit&hellip;
              </p>

              <h2 className="rotatingText-line">Your Gym</h2>
              <h2 className="rotatingText-line"> Your Journey</h2>
              <h2 className="rotatingText-line">Your Success </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="classes_section" id="classes">
        <div className="wrapper_classes_section">
          <div className="men_classes">
            <h2 className="title">Men's Classes</h2>
            <h3>Strength Training Mastery</h3>
            <p>
              Embark on a transformative fitness journey with our dedicated
              men's strength training classes. Whether you're a beginner or a
              seasoned gym enthusiast, our expert instructors will guide you
              through dynamic workouts focused on building muscle, enhancing
              endurance, and achieving your strength goals.
            </p>

            <h3>High-Intensity Interval Training (HIIT)</h3>
            <p>
              Experience the thrill of our high-energy HIIT classes designed to
              push your limits and elevate your fitness. Our men's HIIT sessions
              are crafted to maximize calorie burn, improve cardiovascular
              health, and leave you feeling energized and accomplished.
            </p>
            <button className="contactUsButton">Call For Details</button>
          </div>

          <div className="women_classes">
            <h2 className="title">Women's Classes</h2>
            <div>
              <h3>Cardio Bliss for Women</h3>
              <p>
                Join our women's cardio workout sessions to discover the joy of
                heart-pumping exercises in a supportive and empowering
                environment. Designed exclusively for women, these classes focus
                on improving cardiovascular fitness, boosting metabolism, and
                fostering a sense of community.
              </p>

              <h3>Pilates and Core Conditioning</h3>
              <p>
                Elevate your strength and flexibility with our women's Pilates
                and core conditioning classes. Led by certified instructors,
                these sessions emphasize controlled movements to enhance your
                core strength, posture, and overall well-being.
              </p>
              <a href="tel:+16472255955">
                <button className="contactUsButton">Call For Details</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="about_time_section">
        <div className="wrapper_about_time" id="about">
          <h2 className="red_title">KEEP YOUR BODY FIT & STRONG</h2>
          <h2>Working Hours</h2>
          <p
            style={{
              color: "white",
              marginRight: "8px",
            }}
          >
            We are dedicated to helping you achieve your fitness goals. Our gym
            is open during the following hours:
          </p>
          <ul
            style={{
              color: "white",
              listStyleType: "none",
              paddingInlineStart: "0",
            }}
          >
            <li>
              <strong
                style={{
                  color: " #f7612c",
                  marginRight: "8px",
                }}
              >
                Monday - Friday:{" "}
              </strong>{" "}
              6:00 AM - 10:00 PM
            </li>
            <li>
              <strong
                style={{
                  color: " #f7612c",
                  marginRight: "8px",
                }}
              >
                Saturday:{" "}
              </strong>{" "}
              8:00 AM - 8:00 PM
            </li>
            <li>
              <strong
                style={{
                  color: " #f7612c",
                  marginRight: "8px",
                }}
              >
                Sunday:{" "}
              </strong>{" "}
              Closed
            </li>
          </ul>
          <p
            style={{
              color: "white",
              marginBlockEnd: "0",
              padding: "30px",
            }}
          >
            Please feel free to visit us during these hours to explore our
            state-of-the-art facilities and join our fitness classes.
          </p>
        </div>
      </section>
      <section className="about_section">
        <div className="wrapper_about">
          <h2 className="title">Why Choose Sporty</h2>
          <ul
            style={{
              color: "white",
              listStyleType: "none",
              paddingInlineStart: "0",
            }}
          >
            <li>
              <strong>Expert Instructors:</strong> Our certified instructors
              bring a wealth of experience and passion to every class, ensuring
              a safe and effective workout experience.
            </li>
            <li>
              <strong>Inclusive Community:</strong> At Sporty, we celebrate
              diversity and create a welcoming atmosphere where everyone feels
              comfortable and supported.
            </li>
            <li>
              <strong>State-of-the-Art Facilities:</strong> Enjoy top-notch
              fitness amenities and modern equipment that complement your
              fitness journey.
            </li>
          </ul>
        </div>
      </section>

      <TrainerCard />

      <section className="footer" id="contact">
        <div className="footer_conteiner">
          <div className="footer_sub_container">
            <div className="logo">
              <div className="iconLogo"></div>
              <div className="company_name" style={{ color: " #f6212c" }}>
                Sporty
              </div>
            </div>

            <p>
              Transforming Lives, Building Dreams: Sporty - Your Ultimate
              Fitness Destination in Toronto. Elevate Your Strength, Ignite Your
              Passion, and Achieve Your Fitness Goals with Us. Don't miss the
              opportunity to join our community of champions. <br></br>
              <br></br>Looking forward to hearing from you!
            </p>
          </div>
          <div className="contact" style={{ color: " #f6212c" }}>
            <div className="contact_info" style={{ color: " #f7612c" }}>
              Contact Information:
            </div>
            <br></br>
            Email:
            <a
              href="mailto:info@spoty.com"
              rel="noreferrer"
              target="_blank"
              style={{ color: "white" }}
            >
              info@spoty.com
            </a>
            <br />
            Phone:
            <a style={{ color: "white" }} href="tel:+16472222222">
              +1-647-22-22-2-22
            </a>
            <div className="footer_icons">
              <a href="tel:+16472222222">
                <CiPhone className="footer_icon" />
              </a>
              <a href="mailto:info@lilays.com" rel="noreferrer" target="_blank">
                <CiMail className="footer_icon" />
              </a>
              {/* <a
                href="https://wa.me/+972547759534"
                target="_blank"
                rel="noreferrer"
              > */}
              <PiWhatsappLogoLight className="footer_icon" />
              {/* </a> */}
            </div>
            <br></br>
            <div style={{ color: "white" }}>
              © 2023 Sporty. All rights reserved. Developed by{" "}
              <a
                href="https://lilays.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Lilays
              </a>
              .
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
